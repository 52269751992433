import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import HeaderImage from "../components/header-image"
import Page from "../components/page"
import Section from "../components/section"
import styled from "styled-components"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import MoreInfoTiles from "../components/more-info-tiles"

let StyledAccordian = styled(Accordion)`
  margin: 30px auto 0 auto;
  p{
    text-align: left;
    max-width: 90%;
  }
`

const FaqPage = ({ location }) => {
  const data = useStaticQuery(graphql`
      {
          wpgraphql {
              pageBy(uri: "faq") {
                  id
                  slug
                  seo {
                      title
                      metaDesc
                  }
                  faqPage {
                      text
                      title
                      headerImage {
                          sourceUrl
                          altText
                          imageFile {
                              childImageSharp {
                                  fluid (quality: 90) {
                                      ...GatsbyImageSharpFluid_withWebp
                                  }
                              }
                          }
                      }
                      faqItems {
                          question
                          answer
                      }
                  }
              }
          }
      }
  `)

  let seoData = data.wpgraphql.pageBy.seo
  let pageData = data.wpgraphql.pageBy.faqPage

  return(
    <Page
        seoTitle={ seoData.title }
        seoDescription={ seoData.metaDesc }
    >
      <HeaderImage
        image={ pageData.headerImage }
      />
      <Section>
        <h1 dangerouslySetInnerHTML={{ __html: pageData.title }}/>
        <p dangerouslySetInnerHTML={{ __html: pageData.text }}/>

        <StyledAccordian
          allowZeroExpanded={ true }
        >

          { pageData.faqItems.map(( { question, answer }, index ) => {
            return (

              <AccordionItem
                key={ index }
              >
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span dangerouslySetInnerHTML={{ __html: question }} />
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p dangerouslySetInnerHTML={{ __html: answer }}/>
                </AccordionItemPanel>
              </AccordionItem>

            )})}

        </StyledAccordian>
      </Section>

      <Section backGColour={ 'white' }>
        <MoreInfoTiles
          location={ location }
        />
      </Section>
    </Page>
  )
}
export default FaqPage
